.footer-8A0D {
  background-color: #111111; }

.fade-27F8 {
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #fc5c7d, #6a82fb); }

.content-9B01 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin: 16px;
  text-align: center;
  div {
    height: min-content; } }

.text-D3F7 {
  opacity: 0.3; }

.version-C541 {
  text-decoration: underline;
  cursor: pointer; }
