.pages.notFound.notFoundPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;


  .title {
    font-size: 12em;
    font-weight: bold;
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

  .description {
    font-size: 4em;
    font-weight: bold;
    padding-bottom: 1em;
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

  .button {
    font-size: 2em;
    padding-bottom: 4em;
    background: linear-gradient(to right, #fc5c7d, #6a82fb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;

    .underline {
      height: 2px;
      background: linear-gradient(to right, #fc5c7d, #6a82fb);
      width: 100%; } } }

