.copy-56C9 {
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer; }

.display-454A {
  font-size: 0.75em;
  opacity: 0.75; }

.success-1020 {
  color: green; }

.icon-1CE1 {
  font-size: 2em; }

.hidden-B046 {
  height: 0px;
  overflow: hidden;
  opacity: 0; }
