.container-4DA4 {
  margin: 4px;
  cursor: pointer;
  transition: color 200ms ease;
  color: #696969; }

.container-4DA4:hover {
  color: white; }

.icon-CC65 {
  font-size: 1.4em; }
