.sidebar-EF0D {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0; }

.menu-FFDC {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.menu-FFDC::before {
  content: none; }

.menu-FFDC::after {
  content: none; }
