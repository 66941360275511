.popover-F3BC {
  display: flex;
  flex-direction: row; }

.title-AA5C {
  width: 3em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;

  div {
    width: 1em;
    margin: 0 0.25em 0 0.25em; } }

.col-2354 {
  width: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.row-4C02 {
  display: flex;
  flex-direction: row;
  margin: 2px 0px 2px 0px; }

.icon-8E3E {
  margin: 1px;

  img {
    width: 20px;
    height: 20px; }

  img:hover {
    width: 20px;
    height: 20px;
    filter: drop-shadow(0 0 0.4em rgba(255, 255, 255, 0.5)); } }


.unselected-B125 {
  filter: grayscale(0.5);
  cursor: pointer; }
