.pages.home.homePage {
  height: 100%;
  .card {
    height: 100%; }

  .ant-card-body {
    width: 100%;
    height: 100%; }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 5em;
    font-weight: bold;
    padding-bottom: 1em;
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; } }
