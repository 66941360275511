.row-F0B3 {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, .1);
  margin: 0 0 .4em 0; }

.title-588D {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  img {
    width: 1.4em;
    height: 1.4em;
    margin-right: 8px; }

  .ant-typography-edit {
    color: inherit !important; } }

.spec-0A30 {
  color: #696969;
  letter-spacing: 2px;
  text-transform: uppercase; }

.row-B394 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center; }
