.pages.version.versionPage {
  display: flex;

  > div:nth-child(odd) {
    margin-right: 16px;
    flex-grow: 2; }


  .commit {
    display: flex;

    .button {
      margin-left: 4px; } } }
